
import { SelectOption } from '@/models/SelectOption'
import { ProgressBarStep } from '@/models/ProgressBarStep'
import { Component, Vue } from 'vue-property-decorator'
import FSelect from '@/components/Form/FSelect.vue'
import FButton from '@/components/Button/FButton.vue'
import FInput from '@/components/Form/FInput.vue'
import BusyOverlay from '@/components/BusyOverlay/BusyOverlay.vue'
import FForm from '@/components/Form/FForm.vue'
import Card from '@/components/Card/Card.vue'
import Alert from '@/components/Alert/Alert.vue'
import Badge from '@/components/Badge/Badge.vue'
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue'
import FTabs from '@/components/Tabs/FTabs.vue'
import FTab from '@/components/Tabs/FTab.vue'
import Pagination from '@/components/Pagination/Pagination.vue'
import Modal from '@/components/Modal/Modal.vue'
import HelpToggle from '@/components/HelpToggle/HelpToggle.vue'
import Dropdown from '@/components/Dropdown/Dropdown.vue'
import DropdownItem from '@/components/Dropdown/DropdownItem.vue'
import DropdownDivider from '@/components/Dropdown/DropdownDivider.vue'
import CardWithTitle from '@/components/Card/CardWithTitle.vue'
import CardPanel from '@/components/Card/CardPanel.vue'
import PaginatedSortableTable from '@/components/Table/PaginatedSortableTable.vue'
import SortableTable from '@/components/Table/SortableTable.vue'
import PaginatedTable from '@/components/Table/PaginatedTable.vue'
import Table from '@/components/Table/Table.vue'
import ExpandableTable from '@/components/Table/ExpandableTable.vue'
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'
import CImage from '@/components/Image/CImage.vue'
import Tooltip from '@/components/Tooltip/Tooltip.vue'
import DraggableContainer from '@/components/Draggable/DraggableContainer.vue'
import { TableColumn } from '@/models/TableColumn'
import { BusyService } from '@/services/BusyService'
import { ToastService } from '@/services/ToastService'
import { SiteModalService } from '@/services/SiteModalService'
import { SiteBannerService } from '@/services/SiteBannerService'

@Component({
  components: {
    FSelect,
    FButton,
    FInput,
    Card,
    Alert,
    Badge,
    ProgressBar,
    FTabs,
    FTab,
    Pagination,
    FForm,
    BusyOverlay,
    Modal,
    HelpToggle,
    Dropdown,
    DropdownItem,
    DropdownDivider,
    CardWithTitle,
    CardPanel,
    PaginatedSortableTable,
    SortableTable,
    Table,
    PaginatedTable,
    ExpandableTable,
    SvgIcon,
    DraggableContainer,
    Tooltip,
    CImage,
  },
  metaInfo:{
    title: 'UI'
  },
  beforeRouteEnter:(to, from, next) => {
    if (process.env.NODE_ENV === 'production'){
      next('/');
    }
    next();
  }
})
export default class UI extends Vue {

  tableColumns: TableColumn[] = [
    { key: 'select', type: 'checkbox', sortable: false },
    { key: 'id', type: 'number', label: 'Id', sortable: true, selected: true },
    { key: 'name', label: 'Name', sortable: true },
    { key: 'email', label: 'Email', sortable: true }
  ]

  noSelectTableColumns: TableColumn[] = [
    { key: 'id', type: 'number', label: 'Id', sortable: true, selected: true },
    { key: 'name', label: 'Name', sortable: true },
    { key: 'email', label: 'Email', sortable: true }
  ]

  FSelectOptions: SelectOption[] = [
    { value: 'testa', label: 'Test A' },
    { value: 'testb', label: 'Test B' },
    { value: 'testc', label: 'Test C' },
    { value: 'testd', label: 'Test D' },
    { value: 'teste', label: 'Test E' }
  ]

  progressBarSteps: ProgressBarStep[] = [
    {
      icon: 'shopping-cart',
      description: 'Shopping'
    },
    {
      icon: 'money-check',
      description: 'Checkout'
    },
    {
      icon: 'check',
      description: 'Complete'
    }
  ]

  rows = [
    {
      id: '1',
      name: 'John Doe 1',
      email: 'jdoe@ccli.com'
    },
    {
      id: '2',
      name: 'John Doe 2',
      email: 'jdoe@ccli.com'
    },
    {
      id: '3',
      name: 'John Doe 3',
      email: 'jdoe@ccli.com'
    },
    {
      id: '4',
      name: 'John Doe 4',
      email: 'jdoe@ccli.com'
    },
    {
      id: '5',
      name: 'John Doe 5',
      email: 'jdoe@ccli.com'
    },
    {
      id: '6',
      name: 'John Doe 6',
      email: 'jdoe@ccli.com'
    },
    {
      id: '7',
      name: 'John Doe 7',
      email: 'jdoe@ccli.com'
    },
    {
      id: '8',
      name: 'John Doe 8',
      email: 'jdoe@ccli.com'
    },
    {
      id: '9',
      name: 'John Doe 9',
      email: 'jdoe@ccli.com'
    },
    {
      id: '10',
      name: 'John Doe 10',
      email: 'jdoe@ccli.com'
    },
    {
      id: '11',
      name: 'John Doe 11',
      email: 'jdoe@ccli.com'
    }

  ]

  sections: {name: string, label: string}[] = [];

  mounted (){
    this.$el.querySelectorAll('#main-ui h2.ui-header').forEach(e => {
      const text = (e as HTMLElement).innerText
      const shortText = text.toLowerCase().replaceAll(' ', '-')
      this.sections.push({ name: shortText, label: text })
      const a = document.createElement('a')
      a.setAttribute('name', shortText)
      e.prepend(a)
    })
  }

  toggleBusy() {
    BusyService.showBusy();
    setTimeout(() => BusyService.hideBusy(), 3000)
  }

  createToast (variant: 'success' | 'danger')  {
    ToastService.createToast({ variant: variant, message: 'This is a test message' })
  }

  showSiteBanner () {
    SiteBannerService.show({
      message: 'You have one or more files ready for download',
      actionLabel: 'Go To Download',
      onAction: () => {  }
    })
  }
  showModalMessage() {
    SiteModalService.showModalMessage({
      title: 'Message Test',
      message: 'Make sure you show the modal message which can be programatically shown without creating a new component'
    });
  }
}
