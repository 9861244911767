
import { Component, Prop, Vue } from 'vue-property-decorator'
import FTab from './FTab.vue'

@Component
export default class FTabs extends Vue {
  tabs: string[] = [];

  @Prop({ default: '' })
  value!: string;

  currentTabIndex = 0;

  mounted() {
    this.tabs = this.$slots.default?.filter(c => c.componentOptions?.tag === 'FTab')
      .map(c => (c.componentOptions?.propsData as {title: string}).title || '') || []

    this.changeCurrentTab(0)
  }

  changeCurrentTab(index: number) {
    this.currentTabIndex = index
    this.$children.forEach((c, i) => {
      (c as FTab).active = i === this.currentTabIndex
    })
  }
}
