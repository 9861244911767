
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Blank extends Vue {
  @Prop({ default: 'primary' })
  variant!: string;

  get badgeClasses () : string {
    const classes = []
    classes.push(`bg-${this.variant}`)
    if (
      this.variant === 'light' ||
      this.variant === 'success' ||
      this.variant === 'warning'
    ) {
      classes.push('text-dark')
      classes.push('border')
    }
    return classes.join(' ')
  }
}
